import React, { useContext, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCaretDown, faChevronLeft, faEye, faShare } from '@fortawesome/free-solid-svg-icons';
import { GlobalButtonFinal, ModalsWrapper, ModalWindow } from '../GlobalStyles';
import { Container } from 'react-bootstrap';
import SharePerson from './share_person';
import ShareTeam from './share_team';
import { faLink, faUser } from '@fortawesome/pro-regular-svg-icons';
// //
import { ChromePicker } from "react-color";
// import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { getCookie } from 'cookies-next/lib';
import { LoaderProducts } from '../../containers/Dashboard';
import { borderRadius30, borderRadius22, borderRadius50percent} from '../../global/Variables.js';


import * as Sentry from '@sentry/nextjs';
import messages from '../../../../src/messages';
import { FormattedMessage } from 'react-intl';
import { LangContext } from '../../../../src/ConnectedIntlProvider';


export const StyledPickerColor = styled.div`

    width: 40px;
    height: 20px;
    border: 1px solid lightgray;
    margin-left: 11px;
    top: 4px;
    position: relative;
    transition:all 0.3s ease;
    
    &:hover,
    &:focus{
        border: 1px solid #3D55D7;
    }
`;

export const StyledSplitterHeadline = styled.div`
  

        h3 {
            margin: 0;

            position: relative;
            display: inline-block;
            width: 100%;
            text-align:center;

            span {
                background: white;
                position: relative;
                z-index: 2;
                padding-left: 1rem;
                padding-right: 1rem;
                color:var(--black-40, #999);
             ///   margin-left: 184px;
                text-align: center;
            }

            &::before, &::after {
                display: block;
                content: '';
                z-index: 1;
                background: #D9D9D9 !important;
                position: absolute;
                width: 100% !important;
                height: 1px;
            }

            &::before {
                top: 60%;
            }

            &::after {
                top: 60%;
                bottom: 7px;
            }
        }
    
`;

export const EmbedOptions = styled.div`
    >div{
        width:fit-content;
        display:inline-block;
        min-width:70px;
        input{
            display: inline-block;
            width: 50% !important;
        }
    }
    span{
        font-size:16px;
    }
    textarea{
        margin-top:30px;
    }

`;

export const StyledInputWhispererShare = styled.div`
position:relative;
    margin-bottom: 30px;


    .search-icon{
        position: absolute;
        top: 24px;
        left: 9px;
        width: 19px;
        height: 19px;
        display: block;
        margin-left: 0;
        opacity:0.7;
    }
    
    .closeWhisperer{
        position: absolute;
        top: 24px;
        right: 9px;
        width: 19px;
        height: 19px;
        display: block;
        margin-left: 0;
        opacity:0.5;
        &:hover{
            cursor:pointer;
            opacity:1;
        }
    }
    input{
  
        padding-left:35px !important;
        color:#666 !important;
        &::placeholder {
            color:rgb(102 102 102 / 50%) !important;
        }
   
        
        &:hover,
        &:focus,
        &:active{
            border-color:#3D55D7;
        }
    }
    input.withWhisp{
        border-bottom-left-radius:0px;
        border-bottom-right-radius:0px;
        border-color:#3D55D7;
        border-bottom-color:transparent;
       
    }
    .whisperer{
        box-sizing: border-box;
        display: block;
        border-left: 1px solid #3D55D7;
        border-right: 1px solid #3D55D7;
        border-bottom:1px solid #3D55D7;
        border-bottom-left-radius:10px;
        border-bottom-right-radius:10px;
        padding:5px;
      //  height: fit-content;
        display: grid;
        position: absolute;
        width: 100%;
        background:white;
        z-index: 999999;
        margin-top:-1px;
        
        
        
        .person{
            height: 63px;
            width: 100%;
            display: block;
            position: relative;
            padding: 5px;
            border-radius: ${borderRadius30};
        transition:all 0.3s ease;
            
            
            &:hover{
                background:#F2F2F2;
                cursor:pointer;
            }
        }
    
    }
    .whisperer.hide{
        display:none;
    }
    .whisperer .person {
        >div{
            display:inline-block;
            float:left;   
        }

     
    }
    .contactButtons{
        width: 56%;
        padding-top: 12px;
        height: 53px;
        text-align: right;
        position:relative;
    }
    .contactThumb{
        width:53px;
        height:53px;
        position:relative;

        border-radius: ${borderRadius50percent};
        &.withBG{
            background:#D9D9D9;
        }
        svg{
            color:white;
            left:50%;
            top:50%;
            width:80%;
            font-size: 14pt !important;
            position:absolute;
            transform:translate(-50%, -50%);
        }
        img{
            color:white;
            left:50%;
            top:50%;
            width:80%;
            border-radius: ${borderRadius50percent};
            width:53px !important;
            height:53px !important;
      
            position:absolute;
            transform:translate(-50%, -50%);
        }
    }
    .contactInfo{
        padding-left: 15px;
        text-align: left;
        position: relative;


        width: 73%;
        height:53px;

        &.modal{
            padding-top: 7px;
        }
    }
    
    
    

`;



export const SearchAutoC = styled.div`
    z-index: 99 !important;

    .jVORrT {
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin: 0 0 0 16px;
        fill: grey;
    }

    .dYrfwz {
        min-height: 44px;
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .dYrfwz > input {
        width: 100%;
        padding: 0 0 0 13px;
        border: none;
        outline: none;
        background-color: rgba(0, 0, 0, 0);
        font-size: inherit;
        font-family: inherit;
        color: #212121;
    }

    .dYrfwz > input::placeholder {
        color: grey;
        opacity: 1;
    }

    .dYrfwz > input::-webkit-input-placeholder:-ms-input-placeholder {
        color: grey;
    }

    .dYrfwz > input::-moz-placeholder:-ms-input-placeholder {
        color: grey;
    }

    .dYrfwz > input:-ms-input-placeholder:-ms-input-placeholder {
        color: grey;
    }

    .dYrfwz > input::placeholder:-ms-input-placeholder {
        color: grey;
    }

    .dYrfwz > input::placeholder::-ms-input-placeholder {
        color: grey;
    }

    .jiMOeR {
        position: relative;
        height: 46px;
    }

    .jiMOeR .wrapper {
        position: absolute;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        border: 1px solid #dfe1e5;
        border-radius: ${borderRadius22};
        background-color: white;
        color: #212121;
        font-size: 16px;
        font-family: Arial;
        z-index: 0;
    }

    .jiMOeR .wrapper:hover {
        box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
    }

    .jiMOeR .wrapper:active {
        box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
    }

    .jiMOeR .wrapper:focus-within {
        box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
    }

    //////////////////////////

    .search-icon {
        display: block;
    }

    .clear-icon {
        opacity: 0.5;
        transition: all 0.3s ease;

        &.hidden {
            opacity: 0;
        }
    }

    /*    > .wrapper {
        overflow: hidden;
    }*/

    .search-autoc-people {
        //z-index:9999;

        &.hide {
            display: none;
        }
    }

    li {
        padding-left: 15px;
        padding-top: 7px;
        padding-bottom: 3px;
        transition: all 0.3s ease;

        &:hover {
            background: lightgrey;
        }
    }

    ul {
        list-style-type: none;
        width: 100%;
        height: fit-content;
        max-height: 300px;
        display: inline-grid;
        margin: 0;
        padding: 0;
    }
`;

export default function ShareDialog({ props: any, changeShareShow, triggerWarning, slugState, typeEditor, showShareDialog, showShareDialogTransition, disableLoad }) {
    const [activeTab, setActiveTab] = useState('version');

    const [emailItem, setEmailItem] = useState([]);

    const [useEmailItem, changeUseEmailItem] = useState(false);

    const { language, setLanguage } = useContext(LangContext);
    const [itemsSearch, setItemsSearch] = useState([
        {
            id: 0,
            name: '',
            email: '',
            image: '',
        },
    ]);

    const [backgroundEmbed, setBackgroundEmbed] = useState("#dddddd");

    const [whlEmbed, setWhLEmbed] = useState(true);
    const [frameEmbed, setFrameEmbed] = useState(false);
    const [menuEmbed, setMenuEmbed] = useState(false);
    const [autoplayEmbed, setAutoplayEmbed] = useState(true);




    const [itemsSearchOriginal, setItemsSearchOriginal] = useState([]);

    const [versionSharePeople, setVersionSharePeople] = useState([]);
    const [productSharePeople, setProductSharePeople] = useState([]);

    const [versionOthersSharePeople, setOthersVersionSharePeople] = useState([]);
    const [productOthersSharePeople, setOthersProductSharePeople] = useState([]);

    const [othersVersions, setOthersVersion] = useState(false);
    const [othersProducts, setOthersProduct] = useState(false);

    const [publicLink, setPublicLink] = useState(null);
    const [currentVersionShare, setCurrentVersionShare] = useState(0);
    const [currentProductName, setCurrenProductName] = useState('Product Name');

    const [shareTeamName, setShareTeamName] = useState(null);

    const [shareTeamLogo, setShareTeamLogo] = useState(null);
    const [shareTeamRole, setShareTeamRole] = useState(null);

    const [shareTeamRoleVersion, setShareTeamRoleVersion] = useState(null);

    const [shareVersionUUID, setShareVersionUUID] = useState(null);
    const [shareProductUUID, setShareProductUUID] = useState(null);

    const [customShareMessage, setCustomShareMessage] = useState('');


    const [shareMessageText, setShareMessageText] = useState("");


    const handleShareMessageText = function (e: React.FormEvent<HTMLInputElement>) {
        setShareMessageText(e.currentTarget.value);
    }


    const [shareResponse, setShareResponse] = useState('');
    const sendShareInvites = type => {
        var shareUUID;

        setLoadProductsShow(true);

        if (type === 'version') {
            shareUUID = shareVersionUUID;
        } else {
            shareUUID = shareProductUUID;
        }

        var userShare = [];


        shareInvitePeople.map((value, index) => {
            userShare.push({ uuid: value.id, email: value.email, permissions: value.permission });
        });

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/' + shareUUID + '/members', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'),
            },
            body: JSON.stringify({
                users: userShare,
                message: shareMessageText,
            }),
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('share/' + shareUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                    triggerWarning(data.message);
                }
                setLoadProductsShow(false);
                if (data.message === undefined) {
                    // shareResponse

                    setActiveTab('share_response_tab');
                    setShareInvitePeople([]);
                    setShareResponse(data.success);

loadShareDataAPI();
                    if (type === 'version') {
                        setVersionSharePeople([...data.data.team.members]);
                    } else {
                        setProductSharePeople([...data.data.team.members]);
                    }
                }
            });
    };

    const [inputAutocompleteValue, setInputAutocompleteValue] = useState('');
    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }


    const closeAndClearInput = () => {
        setItemsSearch(itemsSearchOriginal);
        setShowFocusedPeopleAutoC(false);
        setInputAutocompleteValue('');
        changeUseEmailItem(false);

    };

    const handleOnSearchNew = function (e: React.FormEvent<HTMLInputElement>) {
        setInputAutocompleteValue(e.currentTarget.value);

        var firstResultsOriginal = [];
        var string = e.currentTarget.value;


        Object.keys(itemsSearchOriginal).map(key => {


            if ( (itemsSearchOriginal[key]['email']&& itemsSearchOriginal[key]['email'].includes(e.currentTarget.value)) || (itemsSearchOriginal[key]['name'] && itemsSearchOriginal[key]['name'].includes(e.currentTarget.value)) ) {
                firstResultsOriginal.push(itemsSearchOriginal[key]);
            }
        });

        setItemsSearch(firstResultsOriginal);

        if (firstResultsOriginal.length === 0 && e.currentTarget.value.length > 3) {
            changeUseEmailItem(true);
            setEmailItem([
                {
                    name: string,
                    image: null,
                    id: makeid(5),
                    email: string,
                    team_member: false,
                    permission: 'edit',
                },
            ]);
        } else {
            changeUseEmailItem(false);
        }
        if (useEmailItem) {
            setEmailItem([
                {
                    name: string,
                    image: null,
                    id: makeid(5),
                    email: string,
                    team_member: false,
                    permission: 'edit',
                },
            ]);
        }
    };

    const resetShareInvite = location => {
        setShareMessageText("");
        switchShareMessage(false);
        setShareInvitePeople([]);
        setActiveTab(location);
        //   invite
    };

    const [loadProductsShow, setLoadProductsShowVariable] = useState(true);
    const [loadTransitionProductsShow, setLoadTransitionProductsShow] = useState(true);

    const setLoadProductsShow = variable => {

        if (variable === true) {
            setLoadProductsShowVariable(variable);
            setLoadTransitionProductsShow(true);
        } else {
            setTimeout(function () {
                setLoadTransitionProductsShow(false);
                setTimeout(function () {
                    setLoadProductsShowVariable(variable);
                }, 500);
            }, 300);
        }
    };

    const [showFocusedPeopleAutoC, setShowFocusedPeopleAutoC] = useState(false);


  

    const closeWhisperer = () => {
        setWhispererInput("");
         setShowWhisperer(false);
    };


    const handleOnFocusNew = result => {

        if(itemsSearch.length>0){
            setShowWhisperer(true);
        }
        // setShowWhisperer(true);
        setShowFocusedPeopleAutoC(true);
    };


    const selectAutocompleteVersion = item => {

        setShowWhisperer(false);
        if (activeTab === 'version') {
            setActiveTab('share_confirm_version');
        } else {
            setActiveTab('share_confirm');
        }

        var elementInArray = false;


        shareInvitePeople.map(invitePeople => {

            if (item.email === invitePeople.email) {
                elementInArray = true;
            }
        });

        if (!elementInArray) {

            setShareInvitePeople([...shareInvitePeople, item]);
        }

        // handleOnSearchNew(e);
        setInputAutocompleteValue(null);
        setWhispererInput("");

        setShowFocusedPeopleAutoC(false);
        setItemsSearch(itemsSearchOriginal);
        changeUseEmailItem(false);
        setInputAutocompleteValue('');

    };

    const [shareInvitePeople, setShareInvitePeople] = useState([]);

    const [shareMessage, switchShareMessage] = useState(false);




    const handleShareMessage = function (e,shareMessage) {
                e.preventDefault();
        switchShareMessage(shareMessage);
    }

    const [publicLinkOpened, setPublicLinkOpened] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        const handleOutSideClick = event => {
            if (!ref.current?.contains(event.target)) {
                //     alert("Outside Clicked.");
                setPublicLinkOpened(false);
            }
        };

        window.addEventListener('mousedown', handleOutSideClick);

        return () => {
            window.removeEventListener('mousedown', handleOutSideClick);
        };
    }, [ref]);
    const changePublicLink = newPublic => {

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/' + shareVersionUUID, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'),
            },
            body: JSON.stringify({
                team_permissions: 'view',
                public_link: newPublic,
            }),
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('share/' + shareVersionUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }


                if (data.message === undefined) {
                    if (!newPublic) {
                        setPublicLink(null);
                    } else {
                        setPublicLink(data.data.public_link);
                    }

                    //  setPublicLink()
                }
            });
    };

    const changeTeamRole = (newRole, typeShare) => {

        var shareUUID;

        if (shareProductUUID !== null) {
            shareUUID = shareProductUUID;
        }
        if (shareVersionUUID !== null) {
            shareUUID = shareVersionUUID;
        }

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/' + shareUUID, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
            body: JSON.stringify({
                team_permissions: newRole,
                public_link: publicLink === null ? false : true,
            }),
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('share/' + shareUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

                if (data.message === undefined) {
                    if (typeShare === 'version') {
                        setShareTeamRoleVersion(newRole);
                        setVersionSharePeople([...data.data.team.members]);
                    } else {
                        setShareTeamRole(newRole);
                        setProductSharePeople(data.data.team.members);
                    }
                }
            });
    };



    const changePersonInviteRole = (userUUID, permissionNew) => {

        var newArrayPeople = [];
        shareInvitePeople.map((value, index) => {
            if (value.id === userUUID) {
                newArrayPeople.push({ email: value.email,id: value.id,image: value.image,name : value.name,
                    permission: permissionNew, team_member: value.team_member });
            } else {
                newArrayPeople.push(value);
            }
        })


        setShareInvitePeople(newArrayPeople);


    };
    const removePersonInvite = (userUUID) => {




        var newArrayPeople = [];
        shareInvitePeople.map((value, index) => {
            if (value.id === userUUID) {

            } else {
                newArrayPeople.push(value);
            }
        })

        setShareInvitePeople(newArrayPeople);

    };

    const [shareVersionAutocomplete, setShareVersionAutocomplete] = useState([]);

    const loadShareDataAPI = () => {
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/version/' + slugState, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'),
            },
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('editor/' + slugState + ': ' + data.message + ' : ' + getCookie('userMail'));
                   // triggerWarning(data.message);
                } else {


                    if(data.product_version){
                        setCurrentVersionShare(data.product_version.version);
                    }

                    if(data.product_version){
                        setCurrenProductName(data.product.product_name);
                    }



                    if (data && data.product !== null) {
                        setShareProductUUID(data.product.uuid);

                        setShareTeamRole(data.product.team_permissions);
                        setProductSharePeople(data.product.team.members);
                        setShareTeamName(data.product.team.name);
                        setShareTeamLogo(data.product.team.logo);

                        if (data.product.other_members.length > 0) {
                            setOthersProduct(true);
                            setOthersProductSharePeople(data.product.other_members);
                        } else {
                            setOthersProduct(false);
                            setOthersProductSharePeople([]);
                        }

                        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/' + data.product.uuid + '/autocomplete', {
                            method: 'GET',
                            headers: {
                                'Content-type': 'application/json',
                                Authorization: 'Bearer ' + getCookie('userTeam'),
                            },
                        })
                            .then(response => response.json())
                            .then(function (data2) {
                                if (data2.message !== undefined) {

                                    Sentry.captureException('share/' + data2.product.uuid + '/autocomplete' + ': ' + data2.message + ' : ' + getCookie('userMail'));
                                }

                                var newArrayAutocomplete = [];

                                {
                                    data2.map((value, index) => {
                                        newArrayAutocomplete.push({
                                            id: value.user.uuid,
                                            name: value.user.full_name,
                                            email: value.user.email,
                                            team_member: value.team_member,
                                            image: value.user.avatar,
                                            permission: 'edit',
                                        });
                                    });
                                }
                                setItemsSearch(newArrayAutocomplete);


                                setItemsSearchOriginal(newArrayAutocomplete);
                                // setItemsSearchOriginal([{
                                //     id: "asd",
                                //     name: "Jarda",
                                //     email: "jarda@gmail.com",
                                //     team_member: true,
                                //     image: "/images/favicon.png",
                                //     permission: 'edit',
                                // },{
                                //     id: "asdad",
                                //     name: "šmajda",
                                //     email: "zbrna@gmail.com",
                                //     team_member: false,
                                //     image: "/images/play.png",
                                //     permission: 'edit',
                                // },]);

                            });
                    }
                    if (data && data.product_version !== null) {
                        setShareVersionUUID(data.product_version.uuid);
                        setVersionSharePeople(data.product_version.team.members);
                        setShareTeamRoleVersion(data.product_version.team_permissions);
                        if (data.product_version.public_link !== null) {
                            setPublicLink(data.product_version.public_link);
                        }
                        if (data.product_version.other_members.length > 0) {
                            setOthersVersion(true);
                            setOthersVersionSharePeople(data.product_version.other_members);
                        }else {
                            setOthersVersion(false);
                            setOthersVersionSharePeople([]);
                        }
                        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'share/' + data.product_version.uuid + '/autocomplete', {
                            method: 'GET',
                            headers: {
                                'Content-type': 'application/json',
                                Authorization: 'Bearer ' + getCookie('userTeam'),
                            },
                        })
                            .then(response => response.json())
                            .then(function (data2) {

                                if (data2.message !== undefined) {

                                    Sentry.captureException('share/' + data2.product_version.uuid + '/autocomplete' + ': ' + data2.message + ' : ' + getCookie('userMail'));
                                } else {
                                    setShareVersionAutocomplete(data2);
                                }

                            });
                    }

                }



                setLoadProductsShow(false);
            });
    };

    const [shareApiInited, setShareApiInited] = useState(false);


    useEffect(() => {

        if (slugState && !shareApiInited) {
            setShareApiInited(true);
            loadShareDataAPI();
        }
    }, [slugState]);

    const [copiedText, setCopiedText] = useState(language === "cs" ? "Kopírovat odkaz" : 'Copy link');
    const [copyEmbededText, setCopyEmbededText] = useState(language === "cs" ? "Kopírovat embed kód" : 'Copy embed code');


    const copyPublicLink = () => {
        navigator.clipboard.writeText(publicLink);

        if(language === "cs"){
            setCopiedText('Zkopírováno !');
        } else {
            setCopiedText('Copied !');
        }


        setTimeout(function () {

            if(language === "cs"){
                setCopiedText('Kopírovat odkaz');
            } else {
                setCopiedText('Copy link');
            }


        }, 1000);
    };


    const copyEmbedLink = () => {


        navigator.clipboard.writeText("<iframe src='"+publicLink+"?embed=true&background="+backgroundEmbed.replace("#","")+"&autoplay="+autoplayEmbed+"&whitelabel="+whlEmbed+"&frame="+frameEmbed+"&menu="+menuEmbed+"' style='width:100%; height: 400px; border:0'> </iframe>");

        if(language === "cs"){
            setCopyEmbededText('Zkopírováno !');
        } else {
            setCopyEmbededText('Copied !');
        }


        setTimeout(function () {

            if(language === "cs"){
                setCopyEmbededText('Kopírovat embed kód');
            } else {
                setCopyEmbededText('Copy embed code');
            }


        }, 1000);
    };

    const closeModalOutsideClick = e => {

        if( e.target.getAttribute("data-closecolorpicker") !== "false"){

setPickerOpen(false);
        }

        if (e.target.getAttribute('data-closemodal') === 'true') {

            changeShareShow(false);
            setShowFocusedPeopleAutoC(false);
            setInputAutocompleteValue('');
            setItemsSearch(itemsSearchOriginal);
            changeUseEmailItem(false);
        }
    };
    const closeModalOutsideClickAutocomplete = e => {

        if (e.target.getAttribute('data-closemodalautocomplete') === 'true') {

            setShowFocusedPeopleAutoC(false);
            setInputAutocompleteValue('');
            setItemsSearch(itemsSearchOriginal);
            changeUseEmailItem(false);
            setShowWhisperer(false);
        }
    };


    const [showWhisperer, setShowWhisperer] = useState(false);
    const addToContact = (value) => {


        // if(!billingContacts2.includes(value)){
        //     setBillingContacts2( [...billingContacts2, value]);
        // }
        setShowWhisperer(false);

    };

    const [whispererInput, setWhispererInput] = useState("");


    const handleWhisperer = function (e: React.FormEvent<HTMLInputElement>) {
        handleOnSearchNew(e);
        setWhispererInput(e.currentTarget.value);
        if(e.currentTarget.value.length >3){
            setShowWhisperer(true);
        } else {
            setShowWhisperer(false);
        }
    }

    const [color, setColor] = useState("#fff"); // Vybraný color
    const [isPickerOpen, setPickerOpen] = useState(false); // Stav pro otevření/zavření

    return (
        <ModalsWrapper
            className={`  ${showShareDialogTransition ? 'showTransition' : 'hideTransition'} ${!showShareDialog ? 'hiddeComplete' : ''}`}
            data-closemodal={true}
            onClick={e => closeModalOutsideClick(e)}
            style={{zIndex: "99999999999"}}
            // data-closemodalautocomplete={true}
            // onClick={(e)=> closeModalOutsideClickAutocomplete(e)}
        >
            <ModalWindow className={'share'} data-closemodalautocomplete={true}
                         style={{   marginTop:"0px", maxHeight: "90%",display: "block",  overflowY: "scroll"}}
                         data-closewhisperer={true}
                         onClick={e => closeModalOutsideClickAutocomplete(e)}>
                <div className={'modal-header'} data-closemodalautocomplete={true}
                     data-closewhisperer={true}
                >
                    {activeTab === 'product' || activeTab === 'version' ? <h4><FormattedMessage {...messages.products_modal_share_title}></FormattedMessage></h4> : <h4><FormattedMessage {...messages.products_modal_share_product_title}></FormattedMessage></h4>}
                </div>

                <div className={`${activeTab === 'product' || activeTab === 'version' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section" data-closemodalautocomplete={true}
                             data-closewhisperer={true}
                    >
                        <Container data-closemodalautocomplete={true}
                                   data-closewhisperer={true}
                        >
                            <div className="align-center text-center mb-xxs-30" data-closemodalautocomplete={true}
                                 data-closewhisperer={true}
                            >
                                <ul className="nav nav-tabs tpl-minimal-tabs animate" role="tablist">
                                    <li className="nav-item" role="typography" key={"versionTabSwitcherVersion"}>
                                        <a data-closemodalautocomplete={true}      data-closewhisperer={true} onClick={() => setActiveTab('version')} className={`nav-link ${activeTab === 'version' ? 'active' : ''} `}>
                                            <FormattedMessage {...messages.products_modal_share_version_tab}></FormattedMessage>
                                        </a>
                                    </li>

                                    <li className="nav-item" role="typography" key={"versionTabSwitcherProduct"}>
                                        <a data-closemodalautocomplete={true}      data-closewhisperer={true} onClick={() => setActiveTab('product')} className={`nav-link ${activeTab === 'product' ? 'active' : ''} `}>
                                            <FormattedMessage {...messages.products_modal_share_product_tab}></FormattedMessage>
                                        </a>
                                    </li>
                                </ul>

                                {activeTab === 'product' ? (
                                    <span data-closemodalautocomplete={true}       data-closewhisperer={true} className={'infoText'}>
                                        <b> <FormattedMessage {...messages.products_modal_share_description_all_versions}></FormattedMessage> </b> <FormattedMessage {...messages.products_modal_share_description_of}></FormattedMessage> <b>{currentProductName}</b>
                                        <br />
                                            <FormattedMessage {...messages.products_modal_share_description_are_shared}></FormattedMessage>
                                    </span>
                                ) : (
                                    <span data-closemodalautocomplete={true} className={'infoText'}>
                                        <b>  <FormattedMessage {...messages.products_modal_share_description_current}></FormattedMessage>  ({currentVersionShare})</b> <FormattedMessage {...messages.products_modal_share_description_of}></FormattedMessage>  <b>{currentProductName}</b>
                                        <br />
                                        <FormattedMessage {...messages.products_modal_share_description_is_shared}></FormattedMessage>
                                    </span>
                                )}
                            </div>
                        </Container>
                    </section>
                </div>

                <div className={`${activeTab === 'share_confirm' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section">
                        <Container>
                            <div className="align-center text-center mb-xxs-30">
                                <button className={'backButton'} onClick={() => setActiveTab('version')}>
                                    <FontAwesomeIcon icon={faChevronLeft} className={'downIcon'} />
                                </button>
                                <span className={'infoText'}>
                                    <b><FormattedMessage {...messages.products_modal_share_description_all_versions}></FormattedMessage> </b> <FormattedMessage {...messages.products_modal_share_description_of}></FormattedMessage> <b>{currentProductName}</b>
                                    <br />
                                 <FormattedMessage {...messages.products_modal_share_description_are_shared}></FormattedMessage>
                                </span>
                            </div>
                        </Container>
                    </section>

                    <section className="page-section small-section"      data-closewhisperer={true} data-closemodalautocomplete={true}>
                        <div className="container" data-closemodalautocomplete={true}      data-closewhisperer={true}>

                            <StyledInputWhispererShare>

                                <svg className="sc-aXZVg jVORrT search-icon" width="20" height="20" focusable="false"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                </svg>


                                <FormattedMessage {...messages.products_modal_share_input_placeholder}>
                                    {(msg) => (
                                        <input className={showWhisperer ? 'withWhisp' : ''}
                                               type={'text'}
                                               placeholder={msg}
                                               style={{ width: '100%', marginTop: '15px' }}
                                               value={whispererInput}
                                               onFocus={handleOnFocusNew}
                                               onChange={handleWhisperer} />
                                    )}
                                </FormattedMessage>

                                {showWhisperer ?
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 384 512"
                                         className={'closeWhisperer'}
                                         onClick={() => closeWhisperer()}>
                                        <path
                                            d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                    </svg> : null
                                }


                                <div className={showWhisperer ? 'whisperer' : 'whisperer hide'}>

                                    {useEmailItem ? (
                                        <>
                                            {emailItem.map((value, index) => {
                                                return (
                                                    <div className={'person'}
                                                         data-uuid={value.uuid} key={value.uuid}
                                                         onClick={() => selectAutocompleteVersion(value)}>
                                                        <div className={'contactThumb withBG'}
                                                          >

                                                            <FontAwesomeIcon icon={faUser} />
                                                        </div>
                                                        <div className={'contactInfo '} style={{ textAlign: 'left' }}
                                                           >
                                                            {/*<h3>{contactName}</h3>*/}
                                                            <span
                                                                  style={{ textAlign: 'left' }}>{ value.name && value.name.length >30 ? value.name.substring(0, 30) + '...' : value.name}</span>
                                                            <span
                                                                  style={{
                                                                      display: 'block',
                                                                      textAlign: 'left',
                                                                  }}
                                                            >
                                                                            {value.email}
                                                                        </span>

                                                        </div>
                                                        <div className={'contactButtons'}
                                                            >


                                                        </div>
                                                    </div>

                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {itemsSearch.map((value, index) => {

                                                return (

                                                    <div className={'person'}
                                                         data-uuid={value.id}
                                                         key={value.id}
                                                         onClick={() => selectAutocompleteVersion(value)}>
                                                        <div className={'contactThumb'}>
                                                            <img
                                                                style={{
                                                                    display: 'block',
                                                                    textAlign: 'left',
                                                                    maxWidth: '100%',
                                                                }}
                                                                src={value.image}
                                                            />


                                                        </div>
                                                        <div className={'contactInfo '}>
                                                            {/*<h3>{contactName}</h3>*/}
                                                            <span
                                                                  style={{ textAlign: 'left' }}>{ value.name && value.name.length > 30 ? value.name.substring(0, 30) + '...' : value.name}</span>
                                                            <span
                                                                  style={{
                                                                      display: 'block',
                                                                      textAlign: 'left',
                                                                  }}
                                                            >
                                                                            {value.email}
                                                                        </span>

                                                        </div>
                                                        <div className={'contactButtons'}
                                                           >


                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}


                                </div>

                            </StyledInputWhispererShare>


                        </div>
                    </section>

                    <section className="page-section small-section" data-closewhisperer={true}>
                        <Container>

                            <StyledSplitterHeadline>
                                <h3 data-closemodalautocomplete={true} data-closewhisperer={true}>
                                    <span>     <FormattedMessage {...messages.products_modal_share_title_team}></FormattedMessage> </span>
                                </h3>
                            </StyledSplitterHeadline>


                            <div className={'sharePersonsMyTeamContent'}>
                                {shareInvitePeople.map((value, index) => {
                                    if (value.team_member) {
                                        return (
                                            <SharePerson
                                                triggerWarning={triggerWarning}
                                                sharePersonIcon={value.image}
                                                sharePersonName={value.name}
                                                sharePersonEmail={value.email}
                                                sharePersonRole={value.permission}
                                                invite={true}
                                                loadShareDataAPI={loadShareDataAPI}
                                                userUUID={value.id}
                                                shareProductUUID={shareProductUUID}
                                                usrteam={getCookie('userTeam')}
                                                changePersonInviteRole={changePersonInviteRole}
                                                removePersonInvite={removePersonInvite}

                                            />
                                        );
                                    }
                                })}

                                {shareInvitePeople.map((value, index) => {
                                    //  newArrayNotif.push( data[key]);
                                    if (!value.team_member) {
                                        return (
                                            <SharePerson
                                                triggerWarning={triggerWarning}
                                                loadShareDataAPI={loadShareDataAPI}
                                                sharePersonIcon={value.image}
                                                sharePersonName={value.name}
                                                sharePersonEmail={value.email}
                                                sharePersonRole={value.permission}
                                                userUUID={value.id}
                                                invite={true}
                                                shareVersionUUID={shareVersionUUID}
                                                usrteam={getCookie('userTeam')}
                                                changePersonInviteRole={changePersonInviteRole}
                                                removePersonInvite={removePersonInvite}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </Container>
                    </section>


                    <section className="page-section small-section"      data-closewhisperer={true}  style={{marginTop:"40px"}}>
                        <Container className={'shareInvite'}      data-closewhisperer={true}>


                            <StyledSplitterHeadline>
                                <h3 data-closemodalautocomplete={true} data-closewhisperer={true}>
                                    <span>     <FormattedMessage {...messages.products_modal_share_message_title}></FormattedMessage> </span>
                                </h3>
                            </StyledSplitterHeadline>


                            <div className={'inviteMessage'}
                                 onClick={(e)=>handleShareMessage(e,!shareMessage)}
                             >
                                <label className="form-switch" style={{ width: '40px', float: 'left' }}
                                      >
                                    <input type="checkbox"
                                           checked={shareMessage}
                                          />
                                    <i  style={{ top: '0px', left: '-37px' }}></i>
                                </label>
                                <span><FormattedMessage {...messages.products_modal_share_add_invite_message}></FormattedMessage></span>
                            </div>
                            <div>{shareMessage ?
                                <textarea
                                    onChange={handleShareMessageText}
                                    value={shareMessageText}>
                                </textarea> : null}</div>
                        </Container>
                    </section>

                    <section className="page-section small-section actions-section"      data-closewhisperer={true}>
                        <Container      data-closewhisperer={true}>
                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'borderBlue btn-circle '} onClick={() => resetShareInvite('product')}>
                                <FormattedMessage {...messages.products_modal_share_button_cancel}></FormattedMessage>
                            </GlobalButtonFinal>

                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'backgroundBlue btn-circle '} onClick={() => sendShareInvites('product')}>
                                <FontAwesomeIcon className={'iconBtn'} icon={faShare} />  <FormattedMessage {...messages.products_modal_share_button_share}></FormattedMessage>
                            </GlobalButtonFinal>
                        </Container>
                    </section>
                </div>

                <div className={`${activeTab === 'share_confirm_version' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section">
                        <Container>
                            <div className="align-center text-center mb-xxs-30">
                                <button className={'backButton'} onClick={() => resetShareInvite('version')}>
                                    <FontAwesomeIcon icon={faChevronLeft} className={'downIcon'} />
                                </button>
                                {/*Sharing product*/}
                                <span className={'infoText'}>
                                    <b> <FormattedMessage {...messages.products_modal_share_description_all_versions}></FormattedMessage></b> <FormattedMessage {...messages.products_modal_share_description_of}></FormattedMessage> <b>{currentProductName}</b>
                                    <br />
                                    <FormattedMessage {...messages.products_modal_share_description_are_shared}></FormattedMessage>
                                </span>
                            </div>
                        </Container>
                    </section>

                    <section className="page-section small-section" data-closemodalautocomplete={true}      data-closewhisperer={true}>
                        <div className="container" data-closemodalautocomplete={true}      data-closewhisperer={true}>


                            <StyledInputWhispererShare>

                                <svg className="sc-aXZVg jVORrT search-icon" width="20" height="20" focusable="false"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                </svg>


                                <FormattedMessage {...messages.products_modal_share_input_placeholder}>
                                    {(msg) => (

                                        <input className={showWhisperer ? 'withWhisp' : ''}
                                               type={'text'} placeholder={msg}
                                               style={{ width: '100%', marginTop: '15px' }}
                                               value={whispererInput}
                                               onFocus={handleOnFocusNew}
                                               onChange={handleWhisperer} />
                                    )}
                                </FormattedMessage>


                                {showWhisperer ?
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 384 512"
                                         className={'closeWhisperer'}
                                         onClick={() => closeWhisperer()}>
                                        <path
                                            d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                    </svg> : null
                                }

                                <div className={showWhisperer ? 'whisperer' : 'whisperer hide'}>

                                    {useEmailItem ? (
                                        <>
                                            {emailItem.map((value, index) => {
                                                return (
                                                    <div className={'person'}
                                                         data-uuid={value.uuid} key={value.uuid}
                                                         onClick={() => selectAutocompleteVersion(value)}>
                                                        <div className={'contactThumb withBG'}
                                                             >

                                                            <FontAwesomeIcon icon={faUser} />
                                                        </div>
                                                        <div className={'contactInfo '} style={{ textAlign: 'left' }}
                                                         >

                                                            <span
                                                                  style={{ textAlign: 'left' }}>{ value.name && value.name.length > 30 ? value.name.substring(0, 30) + '...' : value.name}</span>
                                                            <span
                                                                  style={{
                                                                      display: 'block',
                                                                      textAlign: 'left',
                                                                  }}
                                                            >
                                                                            {value.email}
                                                                        </span>

                                                        </div>
                                                        <div className={'contactButtons'}
                                                           >


                                                        </div>
                                                    </div>

                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {itemsSearch.map((value, index) => {

                                                return (

                                                    <div className={'person'}
                                                         data-uuid={value.id}
                                                         key={value.id}
                                                         onClick={() => selectAutocompleteVersion(value)}>
                                                        <div className={'contactThumb'}
                                                             >
                                                            <img
                                                                 style={{
                                                                     display: 'block',
                                                                     textAlign: 'left',
                                                                     maxWidth: '100%',
                                                                 }}
                                                                 src={value.image}
                                                            />


                                                        </div>
                                                        <div className={'contactInfo '}
                                                           >

                                                            <span
                                                                  style={{ textAlign: 'left' }}>{ value.name && value.name.length > 30? value.name.substring(0, 30) + '...' : value.name}</span>
                                                            <span
                                                                  style={{
                                                                      display: 'block',
                                                                      textAlign: 'left',
                                                                  }}
                                                            >
                                                                            {value.email}
                                                                        </span>

                                                        </div>
                                                        <div className={'contactButtons'}
                                                            >


                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}


                                </div>

                            </StyledInputWhispererShare>


                        </div>
                    </section>

                    <section className="page-section small-section">
                        <Container>


                            <StyledSplitterHeadline>
                                <h3 data-closemodalautocomplete={true} data-closewhisperer={true}>
                                    <span>     <FormattedMessage {...messages.products_modal_share_title_team}></FormattedMessage> </span>
                                </h3>
                            </StyledSplitterHeadline>




                            <div className={'sharePersonsMyTeamContent'}>
                                {shareInvitePeople.map((value, index) => {

                                        return (
                                            <SharePerson
                                                triggerWarning={triggerWarning}
                                                sharePersonIcon={value.image}
                                                sharePersonName={value.name}
                                                sharePersonEmail={value.email}
                                                sharePersonRole={value.permission}
                                                userUUID={value.id}
                                                invite={true}
                                                changePersonInviteRole={changePersonInviteRole}
                                                removePersonInvite={removePersonInvite}
                                                loadShareDataAPI={loadShareDataAPI}
                                                shareVersionUUID={shareVersionUUID}
                                                usrteam={getCookie('userTeam')}
                                            />
                                        );

                                })}
                            </div>
                        </Container>
                    </section>



                    <section className="page-section small-section" style={{marginTop:"40px"}}>
                        <Container className={'shareInvite'}>


                            <StyledSplitterHeadline>
                                <h3 data-closemodalautocomplete={true} data-closewhisperer={true}>
                                    <span>     <FormattedMessage {...messages.products_modal_share_message_title}></FormattedMessage> </span>
                                </h3>
                            </StyledSplitterHeadline>


                            <div className={'inviteMessage'}
                                 onClick={(e)=>handleShareMessage(e,!shareMessage)}
                            >
                                <label className="form-switch" style={{ width: '40px', float: 'left' }}
                                >
                                    <input type="checkbox"
                                           checked={shareMessage}
                                        //   onChange={(e) => handleShareMessage(!shareMessage)}
                                    />
                                    <i style={{ top: '0px', left: '-37px' }}></i>
                                </label>
                                <span><FormattedMessage {...messages.products_modal_share_add_invite_message}></FormattedMessage></span>
                            </div>


                            <div>{shareMessage ? <textarea
                                onChange={handleShareMessageText}
                                value={shareMessageText}>
                                </textarea> : null}</div>
                        </Container>
                    </section>

                    <section className="page-section small-section actions-section">
                    <Container>
                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'borderBlue btn-circle '}
                                               onClick={() => resetShareInvite('version')}>
                                <FormattedMessage {...messages.products_modal_share_button_cancel}></FormattedMessage>
                            </GlobalButtonFinal>

                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'backgroundBlue btn-circle '}
                                               onClick={() => sendShareInvites('version')}>
                                <FontAwesomeIcon className={'iconBtn'} icon={faShare} />  <FormattedMessage {...messages.products_modal_share_button_share}></FormattedMessage>
                            </GlobalButtonFinal>
                        </Container>
                    </section>
                </div>

                <div className={`${activeTab === 'share_response_tab' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section">
                        <Container>
                            <div className="align-center text-center mb-xxs-30" style={{marginTop:"30px"}}>
                                <span>{shareResponse}</span>
                            </div>
                        </Container>
                    </section>

                    <section className="page-section small-section actions-section">
                        <Container>
                            <GlobalButtonFinal style={{ marginLeft: '15px' }} className={'backgroundBlue btn-circle '} onClick={() => resetShareInvite('version')}>
                                <FormattedMessage {...messages.products_modal_share_button_close}></FormattedMessage>
                            </GlobalButtonFinal>
                        </Container>
                    </section>
                </div>

                <div className={`${activeTab === 'version' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section" data-closemodalautocomplete={true}      data-closewhisperer={true}>
                        <div className="container" data-closemodalautocomplete={true}      data-closewhisperer={true}>

                            <StyledInputWhispererShare>

                                <svg className="sc-aXZVg jVORrT search-icon" width="20" height="20" focusable="false"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                </svg>

                                <FormattedMessage {...messages.products_modal_share_input_placeholder}>
                                    {(msg) => (
                                        <input className={showWhisperer ? 'withWhisp' : ''}
                                               type={'text'} placeholder={msg}
                                               style={{ width: '100%', marginTop: '15px' }}
                                               value={whispererInput}
                                               onFocus={handleOnFocusNew}
                                               onChange={handleWhisperer} />
                                    )}
                                </FormattedMessage>

                                {showWhisperer ?
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 384 512"
                                         className={'closeWhisperer'}
                                         onClick={() => closeWhisperer()}>
                                        <path
                                            d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                    </svg> : null
                                }


                                <div className={showWhisperer ? 'whisperer' : 'whisperer hide'}>

                                    {useEmailItem ? (
                                        <>
                                            {emailItem.map((value, index) => {
                                                return (
                                                    <div className={'person'}
                                                         data-uuid={value.uuid} key={value.uuid}
                                                         onClick={() => selectAutocompleteVersion(value)}>
                                                        <div className={'contactThumb withBG'}
                                                           >

                                                            <FontAwesomeIcon icon={faUser} />
                                                        </div>
                                                        <div className={'contactInfo '} style={{ textAlign: 'left' }}
                                                          >

                                                            <span
                                                                  style={{ textAlign: 'left' }}>{ value.name && value.name.length > 30 ? value.name.substring(0, 30) + '...' : value.name}</span>
                                                            <span
                                                                  style={{
                                                                      display: 'block',
                                                                      textAlign: 'left',
                                                                  }}
                                                            >
                                                                            {value.email}
                                                                        </span>

                                                        </div>
                                                        <div className={'contactButtons'}
                                                             >


                                                        </div>
                                                    </div>

                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {itemsSearch.map((value, index) => {

                                                return (

                                                    <div className={'person'}
                                                         data-uuid={value.id}
                                                         key={value.id}
                                                         onClick={() => selectAutocompleteVersion(value)}>
                                                        <div className={'contactThumb'}
                                                           >
                                                            <img
                                                                 style={{
                                                                     display: 'block',
                                                                     textAlign: 'left',
                                                                     maxWidth: '100%',
                                                                 }}
                                                                 src={value.image}
                                                            />


                                                        </div>
                                                        <div className={'contactInfo '}
                                                             >

                                                            <span
                                                                  style={{ textAlign: 'left' }}>{ value.name && value.name.length > 30 ? value.name.substring(0, 30) + '...' : value.name}</span>
                                                            <span
                                                                  style={{
                                                                      display: 'block',
                                                                      textAlign: 'left',
                                                                  }}
                                                            >
                                                                            {value.email}
                                                                        </span>

                                                        </div>
                                                        <div className={'contactButtons'}
                                                            >


                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}


                                </div>

                            </StyledInputWhispererShare>


                        </div>
                    </section>

                    <section className="page-section small-section" data-closemodalautocomplete={true}
                             data-closewhisperer={true}>
                        <Container data-closemodalautocomplete={true} data-closewhisperer={true}>

                            <StyledSplitterHeadline>
                                <h3 data-closemodalautocomplete={true} data-closewhisperer={true}>
                                    <span>     <FormattedMessage {...messages.products_modal_share_title_team}></FormattedMessage> </span>
                                </h3>
                            </StyledSplitterHeadline>


                            <div className={'sharePersonsMyTeamContent'} data-closemodalautocomplete={true}
                                 data-closewhisperer={true}>

                            <ShareTeam
                                    data-closemodalautocomplete={true}
                                    data-closewhisperer={true}
                                    shareTeamIcon={shareTeamLogo}
                                    shareTeamName={shareTeamName}
                                    shareTeamRole={shareTeamRoleVersion}
                                    changeTeamRole={changeTeamRole}
                                    typeShare={'version'}
                                >
                                    {versionSharePeople.map((value, index) => {
                                        if (value.team_default) {
                                            return (
                                                <SharePerson
                                                    triggerWarning={triggerWarning}
                                                    data-closemodalautocomplete={true}
                                                    data-closewhisperer={true}
                                                    sharePersonIcon={value.user.avatar}
                                                    sharePersonName={value.user.full_name}
                                                    sharePersonEmail={value.user.email}
                                                    sharePersonRole={value.permissions}
                                                    loadShareDataAPI={loadShareDataAPI}
                                                    userUUID={value.user.uuid}
                                                    shareVersionUUID={shareVersionUUID}
                                                    usrteam={getCookie('userTeam')}
                                                />
                                            );
                                        }
                                    })}
                                </ShareTeam>

                                {versionSharePeople.map((value, index) => {
                                    if (!value.team_default) {
                                        return (
                                            <SharePerson
                                                triggerWarning={triggerWarning}
                                                data-closemodalautocomplete={true}
                                                data-closewhisperer={true}
                                                sharePersonIcon={value.user.avatar}
                                                sharePersonName={value.user.full_name}
                                                sharePersonEmail={value.user.email}
                                                userUUID={value.user.uuid}
                                                loadShareDataAPI={loadShareDataAPI}
                                                sharePersonRole={value.permissions}
                                                shareVersionUUID={shareVersionUUID}
                                                usrteam={getCookie('userTeam')}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </Container>
                    </section>

                    {othersVersions ? (
                        <section className="page-section small-section others-section" data-closemodalautocomplete={true}
                                 data-closewhisperer={true}
                        >
                            <Container data-closemodalautocomplete={true}
                                       data-closewhisperer={true}
                            >

                                <StyledSplitterHeadline>
                                    <h3 data-closemodalautocomplete={true} data-closewhisperer={true}>
                                        <span>     <FormattedMessage {...messages.products_modal_share_title_others}></FormattedMessage> </span>
                                    </h3>
                                </StyledSplitterHeadline>


                                {versionOthersSharePeople.map((value, index) => {
                                    //  newArrayNotif.push( data[key]);

                                    return (
                                        <SharePerson
                                            triggerWarning={triggerWarning}
                                            sharePersonIcon={value.user.avatar}
                                            sharePersonName={value.user.full_name}
                                            sharePersonEmail={value.user.email}
                                            userUUID={value.user.uuid}
                                            loadShareDataAPI={loadShareDataAPI}
                                            sharePersonRole={value.permissions}
                                            shareVersionUUID={shareVersionUUID}
                                            usrteam={getCookie('userTeam')}
                                        />
                                    );
                                })}
                            </Container>
                        </section>
                    ) : null}

                    <section className="page-section small-section public-section" data-closemodalautocomplete={true}       data-closewhisperer={true}
                             style={othersProducts ? {} : { marginTop: '30px' }}>
                        <Container data-closemodalautocomplete={true}      data-closewhisperer={true}>

                            <StyledSplitterHeadline>
                                <h3 data-closemodalautocomplete={true} data-closewhisperer={true} style={{marginTop:"15px"}}>
                                    <span>     <FormattedMessage {...messages.products_modal_share_title_public_link}></FormattedMessage> </span>
                                </h3>
                            </StyledSplitterHeadline>


                            <div className={'public_link'}>
                                <div className={'publicLink'}>
                                    <div className={'personIcon'}>
                                        {publicLink !== null ? <img data-closemodalautocomplete={true}      data-closewhisperer={true} style={{ maxWidth: '100%' }} src={'/images/share/Globe.png'} /> : null}
                                    </div>
                                    <div data-closemodalautocomplete={true}      data-closewhisperer={true} className={'personName'}>
                                        <p data-closemodalautocomplete={true}      data-closewhisperer={true}>{publicLink !== null ? <b>  <FormattedMessage {...messages.products_modal_share_description_link_view}></FormattedMessage> </b> : <b><FormattedMessage {...messages.products_modal_share_description_public_link_disabled}></FormattedMessage> </b>}</p>
                                        {publicLink !== null ? <p data-closemodalautocomplete={true}      data-closewhisperer={true}> {publicLink}</p> : <p> <FormattedMessage {...messages.products_modal_share_description_public_link_disabled}></FormattedMessage> </p>}
                                    </div>
                                    <div data-closemodalautocomplete={true} className={'personActions'}>
                                        {publicLink !== null ? (
                                            <button data-closemodalautocomplete={true}      data-closewhisperer={true} onClick={() => setPublicLinkOpened(true)}>
                                                <FormattedMessage {...messages.products_modal_share_button_enabled}></FormattedMessage>  <FontAwesomeIcon icon={faCaretDown} className={'downIcon'} />
                                            </button>
                                        ) : (
                                            <button data-closemodalautocomplete={true}      data-closewhisperer={true} onClick={() => setPublicLinkOpened(true)}>
                                                <FormattedMessage {...messages.products_modal_share_button_disabled}></FormattedMessage>  <FontAwesomeIcon icon={faCaretDown} className={'downIcon'} />
                                            </button>
                                        )}

                                        <div className={`${publicLinkOpened ? 'active sharePersonPopup' : 'sharePersonPopup'} `} ref={ref}>
                                            <ul>
                                                <li data-closemodalautocomplete={true}
                                                    data-closewhisperer={true}
                                                    onClick={() => changePublicLink(true)}
                                                    className={`${publicLink !== null ? 'active' : ''} `}
                                                    key={"viewerPublicLinkViewer"}
                                                >
                                                    <FontAwesomeIcon icon={faEye} className={'downIcon'} />
                                                    <span><FormattedMessage {...messages.products_modal_share_button_enabled}></FormattedMessage> </span>
                                                </li>
                                                <li
                                                    key={"viewerPublicLinkDisabled"}
                                                    data-closemodalautocomplete={true}       data-closewhisperer={true}onClick={() => changePublicLink(false)} className={`${publicLink === null ? 'active' : ''} `}>
                                                    <FontAwesomeIcon icon={faBan} className={'downIcon'} />
                                                    <span><FormattedMessage {...messages.products_modal_share_button_disabled}></FormattedMessage> </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>

                    <section className="page-section small-section actions-section" data-closemodalautocomplete={true}      data-closewhisperer={true}>
                        <Container data-closemodalautocomplete={true}      data-closewhisperer={true} style={{    minHeight: "40px",  display:" inline-block"}}>
                            {publicLink !== null ? (
                                <GlobalButtonFinal

                                    // onMouseLeave={() => setCopiedText("Copy link")}
                                    className={'borderBlue btn-circle'}
                                    onClick={() => copyPublicLink()}
                                >
                                    <FontAwesomeIcon icon={faLink} /> {copiedText}
                                </GlobalButtonFinal>
                            ) : null}

                            <GlobalButtonFinal id="dialogDone" style={{ marginLeft: '15px' }} className={'backgroundBlue btn-circle '} onClick={() => changeShareShow(false)}>
                                <FormattedMessage {...messages.products_modal_share_button_done}></FormattedMessage>
                            </GlobalButtonFinal>
                        </Container>



               {/*         {publicLink !== null ?
                            <Container data-closemodalautocomplete={true} data-closewhisperer={true}
                                       className={'embedLink'}>
                                <StyledSplitterHeadline>
                                    <h3 data-closemodalautocomplete={true} data-closewhisperer={true}
                                        style={{ marginTop: '15px' }}>
                                        <span>     <FormattedMessage {...messages.products_modal_share_title_website_embed}></FormattedMessage> </span>
                                    </h3>
                                </StyledSplitterHeadline>

                                <EmbedOptions>
                                    <div>
                                        <span>Background color:</span>
                                    </div>
                                    <div>
                                        <div>

                                            <StyledPickerColor style={{background:backgroundEmbed}} data-closecolorpicker={"false"}    onClick={() => setPickerOpen(!isPickerOpen)}>

                                            </StyledPickerColor>

                                            {isPickerOpen && (
                                                <div style={{ position: 'absolute', zIndex: 2 }} data-closecolorpicker={"false"}>
                                                    <ChromePicker
                                                        color={backgroundEmbed}
                                                        onChange={(updatedColor) => setBackgroundEmbed(updatedColor.hex)}
                                                    />
                                                </div>
                                            )}


                                        </div>

                                    </div>
                                    <span style={{ marginLeft: '5px', marginRight: '5px', opacity: '0.5' }}> | </span>
                                    <div>
                                        <span>Whitelabel:</span>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            checked={whlEmbed ? true : false}
                                            onClick={()=> setWhLEmbed(true)}
                                        /> <span> Yes</span>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            checked={!whlEmbed ? true : false}
                                            onClick={()=> setWhLEmbed(false)}
                                        /><span> No</span>
                                    </div>
                                </EmbedOptions>


                                <EmbedOptions>
                                    <div>
                                        <span>Grid:</span>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            checked={frameEmbed ? true : false}
                                            onClick={() => setFrameEmbed(true)}
                                        /> <span> Yes</span>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            checked={!frameEmbed ? true : false}
                                            onClick={() => setFrameEmbed(false)}
                                        /><span> No</span>
                                    </div>
                                    <span  style={{marginLeft:"5px", marginRight:"5px", opacity:"0.5"}}> | </span>
                                    <div>
                                        <span>Breadcrumbs:</span>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            checked={menuEmbed ? true : false}
                                            onClick={() => setMenuEmbed(true)}
                                        /> <span> Yes</span>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            checked={!menuEmbed ? true : false}
                                            onClick={() => setMenuEmbed(false)}
                                        /><span> No</span>
                                    </div>

                                </EmbedOptions>

                                <EmbedOptions>
                                    <div>
                                        <span>Autoplay:</span>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            checked={autoplayEmbed === true}
                                            onChange={() => setAutoplayEmbed(true)}
                                        /> <span> Yes</span>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            checked={autoplayEmbed === false}
                                            onChange={() => setAutoplayEmbed(false)}
                                        /><span> No</span>
                                    </div>
                                    <span style={{ marginLeft: '5px', marginRight: '5px', opacity: '0.5' }}> | </span>


                                </EmbedOptions>



                                <textarea style={{
                                    width: '100%',
                                    height: '100px',
                                    color: '#666',
                                    border: '1px solid lightgray',
                                    maxHeight: '100px',
                                    minHeight: '100px',
                                    maxWidth: '100%',
                                    minWidth: '100%',
                                    marginTop:"30px",
                                    marginBottom:"30px",
                                }}
                                          value={'<iframe src=\'' + publicLink + '?embed=true&background=' + backgroundEmbed.replace("#","") + '&whitelabel=' + whlEmbed + '&autoplay='+autoplayEmbed+'&frame=' + frameEmbed + '&menu=' + menuEmbed + '\' style=\'width:100%; height: 400px; border:0\'> </iframe>'}>

                            </textarea>
                                <GlobalButtonFinal

                                    // onMouseLeave={() => setCopiedText("Copy link")}
                                    className={'borderBlue btn-circle'}
                                    onClick={() => copyEmbedLink()}
                                >
                                    <FontAwesomeIcon icon={faLink} /> {copyEmbededText}
                                </GlobalButtonFinal>
                            </Container>
                            : null}*/}

                    </section>
                </div>

                <div className={`${activeTab === 'product' ? 'active shareTab' : 'shareTab'} `}>
                    <section className="page-section small-section" data-closemodalautocomplete={true}
                             data-closewhisperer={true}>
                        <div className="container" data-closemodalautocomplete={true} data-closewhisperer={true}>


                            <StyledInputWhispererShare>

                                <svg className="sc-aXZVg jVORrT search-icon" width="20" height="20" focusable="false"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                                </svg>

                                <FormattedMessage {...messages.products_modal_share_input_placeholder}>
                                    {(msg) => (
                                        <input className={showWhisperer ? 'withWhisp' : ''}
                                               type={'text'} placeholder={msg}
                                               style={{ width: '100%', marginTop: '15px' }}
                                               value={whispererInput}
                                               onFocus={handleOnFocusNew}
                                               onChange={handleWhisperer} />
                                    )}
                                </FormattedMessage>


                                {showWhisperer ?
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 384 512"
                                         className={'closeWhisperer'}
                                         onClick={() => closeWhisperer()}>
                                        <path
                                            d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                    </svg> : null
                                }

                                <div className={showWhisperer ? 'whisperer' : 'whisperer hide'}>

                                    {useEmailItem ? (
                                        <>
                                            {emailItem.map((value, index) => {
                                                return (
                                                    <div className={'person'}
                                                         data-uuid={value.uuid} key={value.uuid}
                                                         onClick={() => selectAutocompleteVersion(value)}>
                                                        <div className={'contactThumb withBG'}
                                                            >

                                                            <FontAwesomeIcon icon={faUser} />
                                                        </div>
                                                        <div className={'contactInfo '} style={{ textAlign: 'left' }}
                                                         >

                                                            <span
                                                                  style={{ textAlign: 'left' }}>{ value.name && value.name.length > 30 ? value.name.substring(0, 30) + '...' : value.name}</span>
                                                            <span
                                                                  style={{
                                                                      display: 'block',
                                                                      textAlign: 'left',
                                                                  }}
                                                            >
                                                                            {value.email}
                                                                        </span>

                                                        </div>
                                                        <div className={'contactButtons'}
                                                           >


                                                        </div>
                                                    </div>

                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {itemsSearch.map((value, index) => {

                                                return (

                                                    <div className={'person'}
                                                         data-uuid={value.id}
                                                         key={value.id}
                                                         onClick={() => selectAutocompleteVersion(value)}>
                                                        <div className={'contactThumb'}
                                                          >
                                                            <img
                                                                style={{
                                                                    display: 'block',
                                                                    textAlign: 'left',
                                                                    maxWidth: '100%',
                                                                }}
                                                                src={value.image}
                                                            />


                                                        </div>
                                                        <div className={'contactInfo '}
                                                          >
                                                            <span
                                                                  style={{ textAlign: 'left' }}>{ value.name && value.name.length > 30 ? value.name.substring(0, 30) + '...' : value.name}</span>
                                                            <span
                                                                  style={{
                                                                      display: 'block',
                                                                      textAlign: 'left',
                                                                  }}
                                                            >
                                                                            {value.email}
                                                                        </span>

                                                        </div>
                                                        <div className={'contactButtons'}
                                                            >


                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}


                                </div>

                            </StyledInputWhispererShare>


                        </div>
                    </section>

                    <section className="page-section small-section">
                        <Container>

                            <StyledSplitterHeadline>
                                <h3 data-closemodalautocomplete={true} data-closewhisperer={true}>
                                    <span>     <FormattedMessage {...messages.products_modal_share_title_team}></FormattedMessage> </span>
                                </h3>
                            </StyledSplitterHeadline>


                            <div className={'sharePersonsMyTeamContent'}>
                                <ShareTeam shareTeamIcon={shareTeamLogo} shareTeamName={shareTeamName} shareTeamRole={shareTeamRole} changeTeamRole={changeTeamRole} typeShare={'product'}>
                                    {productSharePeople.map((value, index) => {
                                        if (value.team_default) {
                                            return (
                                                <SharePerson
                                                    triggerWarning={triggerWarning}
                                                    sharePersonIcon={value.user.avatar}
                                                    sharePersonName={value.user.full_name}
                                                    sharePersonEmail={value.user.email}
                                                    sharePersonRole={value.permissions}
                                                    userUUID={value.user.uuid}
                                                    loadShareDataAPI={loadShareDataAPI}
                                                    shareProductUUID={shareProductUUID}
                                                    usrteam={getCookie('userTeam')}
                                                />
                                            );
                                        }
                                    })}
                                </ShareTeam>

                                {productSharePeople.map((value, index) => {
                                    if (!value.team_default) {
                                        return (
                                            <SharePerson
                                                triggerWarning={triggerWarning}
                                                sharePersonIcon={value.user.avatar}
                                                sharePersonName={value.user.full_name}
                                                sharePersonEmail={value.user.email}
                                                userUUID={value.user.uuid}
                                                shareProductUUID={shareProductUUID}
                                                loadShareDataAPI={loadShareDataAPI}
                                                sharePersonRole={value.permissions}
                                                usrteam={getCookie('userTeam')}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </Container>
                    </section>

                    {othersProducts ? (
                        <section className="page-section small-section others-section">
                            <Container>
                                <StyledSplitterHeadline>
                                    <h3 data-closemodalautocomplete={true} data-closewhisperer={true}>
                                        <span>     <FormattedMessage {...messages.products_modal_share_title_others}></FormattedMessage> </span>
                                    </h3>
                                </StyledSplitterHeadline>

                                {productOthersSharePeople.map((value, index) => {
                                    return (
                                        <SharePerson
                                            triggerWarning={triggerWarning}
                                            sharePersonIcon={value.user.avatar}
                                            sharePersonName={value.user.full_name}
                                            sharePersonEmail={value.user.email}
                                            userUUID={value.user.uuid}
                                            shareProductUUID={shareProductUUID}
                                            loadShareDataAPI={loadShareDataAPI}
                                            sharePersonRole={value.permissions}
                                            usrteam={getCookie('userTeam')}
                                        />
                                    );
                                })}
                            </Container>
                        </section>
                    ) : null}

                    <section className="page-section small-section actions-section">
                        <Container>
                            <GlobalButtonFinal id={"dialogDone2"} style={{ marginLeft: '15px' }} className={'backgroundBlue btn-circle '} onClick={() => changeShareShow(false)}>
                                <FormattedMessage {...messages.products_modal_share_button_done}></FormattedMessage>
                            </GlobalButtonFinal>
                        </Container>
                    </section>
                </div>

                <LoaderProducts className={`share-products-loader ${loadTransitionProductsShow ? 'showTransition' : 'hideTransition'} ${!loadProductsShow ? 'hiddeComplete' : ''} `}>
                    <img src={'/logoAnimate.svg'} />
                </LoaderProducts>

                <div style={{ height: '0', overflow: 'hidden' }}>
                    {/*<ReactSearchAutocomplete />*/}
                </div>
            </ModalWindow>
        </ModalsWrapper>
    );
}
