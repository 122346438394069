import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { useSession } from 'next-auth/client';

import styled from 'styled-components';

import Products from './Products';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Col, Container, Row } from 'react-bootstrap';
import Link from 'next/link';
import { GlobalButtonFinal, ModalsWrapper, ModalWindow } from '../global/GlobalStyles';
import DialogAcceptTeamInvite from '../global/dialogs/DialogAcceptTeamInvite';
import { getCookie, deleteCookie, setCookie } from 'cookies-next';
import * as Sentry from '@sentry/nextjs';
import GroupsFilter from '../pages/products/GroupsFilter';
import messages from '../../../src/messages';
import { FormattedMessage } from 'react-intl';
import DialogAlert from '../global/dialogs/DialogAlert';
import ReactTooltip from 'react-tooltip';
import { LangContext } from '../../../src/ConnectedIntlProvider';
import { log } from '../../logger';

const StyledDashboard = styled.div`
    height: 100%;
padding-top: 80px;
    
    #headlineUnlock{
        font-weight:200 !important;
        opacity:0.6;
        transition:all 0.3s ease;
        &:hover{
            opacity:1;
        }
    }
    .contentHeader{
        position:fixed;
        background: #F2F2F2;
        z-index: 9999999;
        width:100%;
        z-index: 9;
        top: 54px;
        padding-bottom: 15px !important;
        transition:all 0.3s ease;

        .section-header h1 {
            transition:all 0.3s ease;
          
        }
        
        &.scrolled{
            top: 40px;
            .section-header h1 {
            transition:all 0.3s ease;
                font-size: 23px !important;
                margin-bottom:15px !important;
           
            }
        }
    }
    
    @media (max-width: 600px) {
        section {
            padding-left: 15px;
            padding-right: 15px;
        
        }
    }
`;

export const LoaderProducts = styled.div`
    z-index: 9999999;
    background: rgba(255, 255, 255, 1);
    position: fixed;
    left: 0;
    top: 5px;

    width: 100%;
    height: 100%;

    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        //width:100px;
        max-height: 60px !important;
    }

    transition: all 0.5s ease;

    opacity: 0;
    display: block;
    &.showTransition {
        opacity: 1;
    }
    &.hideTransition {
        opacity: 0;
    }

    &.hiddeComplete {
        display: none;
    }
`;

export default function Dashboard({
    props: any,
    openDuplicateDialogAndSetIDZ,
    openDeleteDialogAndSetIDZ,
    toggleReload,
                                      pageReload,
     changeLoadingShowTop,
    openDeleteDialogAndSetIDZMultiple,
    openMoveDialogAndSetIDZ,
                                      setCurrentPageTop,
    openRenameDialogAndSetIDZ,
    deleteProductCheck,
    closeDeleteShow,
    productDeleteUUID,
    changeProductDeleteUUID,
    trigger,
    plugin
}) {
    const router = useRouter();
    const [session, loading] = useSession();
    if (typeof window !== 'undefined' && loading) return null;

    if (!loading && !session) {

        router.push('/');
        return null;
    }

    const [isMediumDisplay, setMediumDisplay] = useState(false);

    const [teamInvitationUUID, setTeamInvitationUUID] = useState(false);
    const { language, setLanguage } = useContext(LangContext);
    let handleClickInvite = () => {};

    //

    useEffect(() => {
        document.getElementById('mainBody').classList.add('gray');
        if (teamInvitationUUID) {
            handleClickInvite();
        }
    }, [teamInvitationUUID]);

    //
    useEffect(() => {
        if (router.query.teamInvitation) {
            setTeamInvitationUUID(router.query.teamInvitation);
        }
        if (window.innerWidth > 1310) {
            setMediumDisplay(true);
        } else {
            setMediumDisplay(false);
        }

        const updateMedia = () => {
            if (window.innerWidth > 1310) {
                setMediumDisplay(true);
            } else {
                setMediumDisplay(false);
            }
        };
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    const [loadProductsShow, setLoadProductsShowVariable] = useState(true);
    const [loadTransitionProductsShow, setLoadTransitionProductsShow] = useState(true);

    const setLoadProductsShow = variable => {

        if (variable === true) {
            setLoadProductsShowVariable(variable);
            setLoadTransitionProductsShow(true);
        } else {
            setTimeout(function () {
                setLoadTransitionProductsShow(false);
                setTimeout(function () {
                    setLoadProductsShowVariable(variable);
                }, 500);
            }, 300);
        }
    };

    let handleTriggerWarning = () => {

    };

    const [currentPlan, setCurrentPlan] = useState("");

    const [warningType, setWarningType] = useState("warning");
    const [warningMessage, setWarningMessage] = useState("");
    let triggerWarning = (warningMessage) => {
        setWarningType("warning");
        setWarningMessage(warningMessage);
        handleTriggerWarning();
    };

    const loadTeamInfoAPI = () => {
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"teams/current", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){
                if(data.message !== undefined){
                    Sentry.captureException("teams/current"+": "+ data.message + " : " + getCookie("userMail"));
                    if(data.error_code){
                        triggerWarning(data.error_code);
                    }else{
                        triggerWarning(data.message);
                    }

                }


                if(data.subscription){
                    setCurrentPlan(data.subscription.plan);
                }


            });
    };

    ///////////// NEW PRODUCT
    const [loadinProductsComplete, setLoadinProductsComplete] = useState(false);


    const [newProductTypes, setNewProducTypes] = useState([]);

    const ListProductTypes = () => {
        var allMainProductGroups = [];
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'package-groups', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function (data) {

                if (data.message !== undefined) {
                    Sentry.captureException('package-groups' + ': ' + data.message + ' : ' + getCookie('userMail'));
                } else {

                    data.map(item => {
                        if (item.parent === null) {
                            allMainProductGroups.push(item);
                        }
                    });
                    setNewProducTypes(allMainProductGroups);
                }


            });
    };

    const [appExternal, setAppExternal] = useState(false);

    const loadUserInfoAPI = () => {
        var tokenMain = getCookie('userTeam');

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/info', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenMain, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {

                if(data.status === 401){

                } else {

                    if (!getCookie('userMail') && (getCookie('userMail') !== data.user.email)) {
                        setCookie('userMail', data.user.email, { maxAge: 10 * 365 * 24 * 60 * 60 });
                    }

                    Object.keys(data.teams).map(key => {
                        if (getCookie('userTeamUUID') === data.teams[key]['uuid']) {

                                if( data.teams[key]['role'] === "external"){
                                    setAppExternal(true);
                                }
                            if( data.teams[key]['role'] === "billing"){

                                router.push('/payments-credits');
                            }



                        }

                    });

                }
                if (data.message !== undefined) {
                    Sentry.captureException('auth/info' + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

            });
    };

    useEffect(() => {
        if (getCookie('reloadProducts') === true) {
            deleteCookie('reloadProducts');
            location.reload();
        }
        ReactTooltip.rebuild();
        loadTeamInfoAPI();
        ListProductTypes();
        loadUserInfoAPI();
    }, []);

    const [newproductsShow, setNewProductsShow] = useState(false);
    const [newproductsTransitionShow, setNewProductsTransitionShow] = useState(false);

    const changeNewProductsShow = variable => {
        if (variable === true) {
            setNewProductsShow(variable);
            setTimeout(function () {
                setNewProductsTransitionShow(true);
            }, 1);
        } else {
            setNewProductsTransitionShow(false);
            setTimeout(function () {
                setNewProductsShow(variable);
            }, 500);
        }
    };

    const closeModalOutsideClick = e => {
        if (e.target.getAttribute('data-closemodal') === 'true') {
            changeNewProductsShow(false);
            if(window.Intercom){
                window.Intercom("hide");
            }
        }
    };

    const [totalPages, setTotalPages] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    const [products, setProducts] = useState([]);

    const [downgradeProducts, setDowngradeProducts] = useState(0);
    const [filterActiveAll, setFiltersActiveAll] = useState(null);

    const setCurrentPageAndLoad= (value)=> {
        var nef2 = {};

        var filterActive = false;

            if (router.query.clients) {
                nef2["clients"] = router.query.clients.split(',');
                filterActive= true;
                //    onChangeAddFilter('clients', router.query.clients.split(','));
                //showFilteredProducts({ clients: router.query.clients.split(',') });
            }
            if (router.query.brands) {
                //  onChangeAddFilter('brands', router.query.brands.split(','));
                //     showFilteredProducts({ brands: router.query.brands.split(',') });
                nef2["brands"] = router.query.brands.split(',');
                filterActive= true;
            }
            if (router.query.editions) {
                nef2["editions"] = router.query.editions.split(',');
                //  onChangeAddFilter('editions', router.query.editions.split(','));
                filterActive= true;
                //showFilteredProducts({ editions: router.query.editions.split(',') });
            }
            if (router.query.product_types) {
                nef2["product_types"] = router.query.product_types.split(',');
                filterActive= true;
                // onChangeAddFilter('product_types', router.query.product_types.split(','));
                //showFilteredProducts({ product_types: router.query.product_types.split(',') });
            }
            if (router.query.volumes) {
                filterActive= true;
                nef2["volumes"] = router.query.volumes.split(',');
                // onChangeAddFilter('volumes', router.query.volumes.split(','));
                //     showFilteredProducts({ volumes: router.query.volumes.split(',') });
            }




        if (filterActiveAll || filterActive) {
            loadProducts(value, filterActiveAll, true);
        } else {
            loadProducts(value, null, true);
        }
     //   loadProducts(value, null, true);

        setCurrentPage(value);
        setCurrentPageTop(value);
    }
    const loadProducts = (pageValue, filters, setURL) => {
        log("LOADING PRODUCTS ", pageValue, filters, setURL);
setCurrentPage(pageValue);
        setCurrentPageTop(pageValue);
        var filtersInternal = {};

        if (filters) {
            setFiltersActiveAll(filters);
            filtersInternal = filters;
            setFilterActive(true);
        }

        let filtersVariableStringClients = '&client=';
        let filtersVariableStringBrands = '&brand=';
        let filtersVariableStringEditions = '&edition=';
        let filtersVariableStringProductTypes = '&product_type=';
        let filtersVariableStringPackages = '&package=';
        let filtersVariableStringVolumes = '&volume=';


        if (filtersInternal.clients && filtersInternal.clients.length > 0) {
            filtersVariableStringClients += filtersInternal.clients.toString();
        } else {
            filtersVariableStringClients = '';
        }
        if (filtersInternal.brands  && filtersInternal.brands.length > 0) {
            filtersVariableStringBrands += filtersInternal.brands.toString();
        } else {
            filtersVariableStringBrands = '';
        }
        if (filtersInternal.editions && filtersInternal.editions.length > 0) {
            filtersVariableStringEditions += filtersInternal.editions.toString();
        } else {
            filtersVariableStringEditions = '';
        }
        if (filtersInternal.product_types && filtersInternal.product_types.length > 0) {
            filtersVariableStringProductTypes += filtersInternal.product_types.toString();
        } else {
            filtersVariableStringProductTypes = '';
        }
        if (filtersInternal.packages && filtersInternal.packages.length > 0) {
            filtersVariableStringPackages += filtersInternal.packages.toString();
        } else {
            filtersVariableStringPackages = '';
        }
        if (filtersInternal.volumes && filtersInternal.volumes.length > 0) {
            filtersVariableStringVolumes += filtersInternal.volumes.toString();
        } else {
            filtersVariableStringVolumes = '';
        }

        var fullVariableString =
            filtersVariableStringClients +
            filtersVariableStringBrands +
            filtersVariableStringEditions +
            filtersVariableStringProductTypes +
            filtersVariableStringPackages +
            filtersVariableStringVolumes;

        neco = 0;
        setLoadProductsShow(true);

        var page = 1;
        if (pageValue) {
            page = pageValue;
        }
        var commT;

        if (plugin) {
            commT = getCookie('pluginLoginSessionTeamToken');
        } else {
            commT = getCookie('userTeam');
        }



        var allProductsArray = [];
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'products?page=' + page + fullVariableString, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + commT, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('products?page=' + page + ': ' + data.message + ' : ' + getCookie('userMail'));
                }


                if (setURL) {

                    const url = new URL(window.location.href); // Parse the current URL
                    const params = new URLSearchParams(url.search); // Get URL parameters

                    if (page !== 1) {
                        params.set('page', page.toString()); // Update or add the 'page' parameter
                    } else {
                        params.delete('page'); // Remove 'page' parameter if it's 1
                    }

                    // Replace the current state with the updated URL
                    window.history.replaceState(null, 'Products', `${url.pathname}?${params.toString()}`);
                }


                window.scrollTo({ top: 0, left: 0 });

                setCurrentPageTop(page);
                setCurrentPage(page);

                if(data.downgraded > 0){
                    setDowngradeProducts(data.downgraded);
                }

                allProductsArray = data.data;
                setTotalPages(data.pages);
                var NewDataProducts = [];
                for (var product in allProductsArray) {
                    allProductsArray[product].productSelected = false;
                    NewDataProducts.push(allProductsArray[product]);
                }
                changeLoadingShowTop(false);

                if(NewDataProducts.length !== 0){
                    setLoadProductsShow(false);
                } else{
                    ListPackageGroupsDetailAPI("root");
                }


                setProducts(NewDataProducts);
                if(page > 1){
                    window.scrollTo({ top: 0, left: 0 });
                }
          //      window.scrollTo({ top: 0, left: 0 });

                setLoadinProductsComplete(true);
            });
    };

    const [pageReloadInner, setPageReloadInner] = useState(1);

    var neco = 0;
    useEffect(() => {

        setPageReloadInner(pageReload);
    }, [pageReload]);



    const toggleReloadInside = useCallback((): void => {

        var newPage = currentPage;
        const params = new URLSearchParams(window.location.search)
       if(params.get('page')){
           newPage = Number(params.get('page'));
       }

        var nef2 = {};

        var filterActive = false;

        if (params.get('clients')) {
            nef2["clients"] = params.get('clients').split(',');
            filterActive= true;
            //    onChangeAddFilter('clients', router.query.clients.split(','));
            //showFilteredProducts({ clients: router.query.clients.split(',') });
        }
        if (params.get('brands')) {
            //  onChangeAddFilter('brands', router.query.brands.split(','));
            //     showFilteredProducts({ brands: router.query.brands.split(',') });
            nef2["brands"] = params.get('brands').split(',');
            filterActive= true;
        }
        if (params.get('editions')) {
            nef2["editions"] = params.get('editions').split(',');
            //  onChangeAddFilter('editions', router.query.editions.split(','));
            filterActive= true;
            //showFilteredProducts({ editions: router.query.editions.split(',') });
        }
        if (params.get('product_types')) {
            nef2["product_types"] = params.get('product_types').split(',');
            filterActive= true;
            // onChangeAddFilter('product_types', router.query.product_types.split(','));
            //showFilteredProducts({ product_types: router.query.product_types.split(',') });
        }
        if (params.get('volumes')) {
            filterActive= true;
            nef2["volumes"] = params.get('volumes').split(',');
            // onChangeAddFilter('volumes', router.query.volumes.split(','));
            //     showFilteredProducts({ volumes: router.query.volumes.split(',') });
        }


        if (filterActive) {
            loadProducts(newPage, nef2, false);
        } else {
            loadProducts(newPage, null, false);
        }


    }, []);
    useEffect(() => {
        toggleReload && toggleReload(toggleReloadInside);
    }, [toggleReload, toggleReloadInside]);



    const [rootPackageGroups, setRootPackageGroups] = useState([]);

    const ListPackageGroupsDetailAPI = (type) =>{

        setLoadProductsShow(true);
        var allMainProductGroups = [];
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"package-groups", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){

                if(data.message !== undefined){

                    Sentry.captureException("package-groups"+": "+ data.message  + " : " + getCookie("userMail"));
                } else {


                    if(type === "root"){
                        setLoadProductsShow(true);
                        data.map(item => {
                            if (item.parent === null && !item.coming_soon) {
                                allMainProductGroups.push(item);
                            }
                        });
                        setRootPackageGroups(allMainProductGroups);
                    } else {

                    }



                }

                setLoadProductsShow(false);

            });
    }


    const [filterClients, setFilterClients] = useState([]);
    const [filterBrands, setFilterBrands] = useState([]);
    const [filterEditions, setFilterEditions] = useState([]);




    const [filteredProductsState, setFilteredProductsState] = useState([]);

    const [filterActive, setFilterActive] = useState(false);

    const [filteredProductsTemp, setFilteredProductsTemp] = useState([]);
    const setFilteredProducts = filteredProducts => {
        setFilteredProductsTemp(filteredProductsTemp);
    };
    const [selectingMultiple, setSelectingMultiple] = useState(false);

    const [selectedProducts, setSelectedProducts] = useState([]);



    const selectingFunction = e => {

        e.preventDefault();
        if (selectingMultiple) {
            if (!selectedProducts.includes(e.target.getAttribute('data-productid'))) {
                addToSelectedProducts(e.target.getAttribute('data-productid'));
            } else {
                removeToSelectedProducts(e.target.getAttribute('data-productid'));
            }
        }
    };

    const openDeleteMultiple = () => {
        setSelectingMultiple(false);
        openDeleteDialogAndSetIDZMultiple(selectedProducts);
    };
    const stopSelectingMultiple = () => {
        let productsNewArray = [];
        let productsNew = [
            ...products.map(item => {
                item.productSelected = false;
                productsNewArray.push(item);
            }),
        ];
        setProducts(productsNewArray);

        setSelectingMultiple(false);
        setSelectedProducts([]);
    };

    const addToSelectedProducts = replace => {
        let selectedProductsNew = [...selectedProducts, replace];
        let productsNewArray = [];

        let productsNew = [
            ...products.map(item => {
                if (item.uuid === replace) {
                    item.productSelected = true;
                }
                productsNewArray.push(item);
            }),
        ];

        setSelectedProducts(selectedProductsNew);
        setProducts(productsNewArray);
    };

    const removeToSelectedProducts = replace => {
        let selectedProductsNew = [...selectedProducts.filter(selectedProduct => selectedProduct !== replace)];
        let productsNewArray = [];

        let productsNew = [
            ...products.map(item => {
                if (item.uuid === replace) {
                    item.productSelected = false;
                }
                productsNewArray.push(item);
            }),
        ];

        setSelectedProducts(selectedProductsNew);
    };

    const [productsInited, setProductsInited] = useState(false);
    useEffect(() => {

        if(!productsInited){
            setProductsInited(true);

            if(router.query.page){

                setCurrentPage(router.query.page);
                setCurrentPageTop(router.query.page);

                loadProducts(router.query.page, null, false);
            }else{
                loadProducts(null,null,false);
            }

        }

    }, [productsInited]);



    const [isClient, setIsClient] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);




    useEffect(() => {
        setIsClient(true);
        if(isClient){
            const onScroll = e => {

               if(e.target.documentElement.scrollTop > 0){
                   setIsScrolled(true);
               }
                if(e.target.documentElement.scrollTop <= 0){
                    setIsScrolled(false);
                }
            };
            window.addEventListener("scroll", onScroll);

            return () => window.removeEventListener("scroll", onScroll);
        }

    }, [isClient]);




    return (
        <>
            <StyledDashboard id={'dashboard'} data-closemodalmenu={true}>


                <section className={isScrolled ? "small-section bg-gray-lighter pt-30 pb-0 contentHeader scrolled"  :  "small-section bg-gray-lighter pt-30 pb-0 contentHeader " }data-closemodalmenu={true}
                         style={{ overflow: 'visible' }}>
                    <Container>
                        <Row>
                            <Col md={12} sm={12} xs={12} lg={12} style={{ position: 'relative' }}
                                 data-closemodalmenu={true} className={'section-header'}>
                                <h1 className="mb-20 mb-xs-0" style={{ display: 'inline-block' }}>
                                    <FormattedMessage {...messages.products_header}></FormattedMessage>
                                </h1>



                                {currentPlan === "Free" && products.length >= 3 ?
                                        <>
                                            {!appExternal ?
                                                    <GlobalButtonFinal
                                                        style={{
                                                            float: 'right',
                                                            padding: "5px",
                                                            paddingLeft: "38px",
                                                            paddingRight: "38px"
                                                        }}
                                                        data-intercom-target="New product button"
                                                        id={"newProductButton"}
                                                        className={'btn-circle backgroundGray newProductButton'}
                                                        data-tip={language === "cs" ? "Maximální počet produktů pro free plan jsou 3" : 'Maximum products for the free plan is 3'}
                                                        data-place="bottom"
                                                        data-delay-show="100"
                                                    >
                                                        <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faPlus}
                                                                         data-tip={language === "cs" ? "Maximální počet produktů pro free plan jsou 3" : 'Maximum products for the free plan is 3'}
                                                                         data-place="bottom"
                                                                         data-delay-show="200" />
                                                        <FormattedMessage {...messages.products_buttons_addnew}></FormattedMessage>
                                                    </GlobalButtonFinal>

                                                : null}
                                        </>
                                    :
                                    <>
                                        {!appExternal ?
                                            <Link href={'/new-product'} data-closemodalmenu={true}>
                                                <GlobalButtonFinal
                                                    style={{
                                                        float: 'right',
                                                        padding: "5px",
                                                        paddingLeft: "38px",
                                                        paddingRight: "38px"
                                                    }}

                                                    className={'btn-circle backgroundBlue' }
                                                >
                                                    <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faPlus} />
                                                    <FormattedMessage {...messages.products_buttons_addnew}></FormattedMessage>
                                                </GlobalButtonFinal>
                                            </Link>
                                            : null}
                                    </>
                                }



                            </Col>
                            <Col md={12} sm={12} xs={12} lg={12} style={{ position: 'relative' }}>
                                <div style={{ position: 'relative', left: '0' }}>

                                    {products.length > 0 || filterActive ?
                                        <GroupsFilter
                                            disabledFilters={currentPlan === 'Free' ? true : false}
                                            setLoadProductsShow={setLoadProductsShow}
                                            setFilterClientsProducts={setFilterClients}
                                            setFilterBrandsProducts={setFilterBrands}
                                            setFilterEditionsProducts={setFilterEditions}
                                            loadProducts={loadProducts}
                                            filterActive={filterActive}
                                            loadinProductsComplete={loadinProductsComplete}
                                            setFilterActive={setFilterActive}
                                        />
                                        : null
                                    }

                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className={'page-section pt-0 bg-gray-lighter'} data-closemodalmenu={true}
                         style={{ paddingBottom: ' 220px' }}>
                    <Container data-closemodalmenu={true}>
                        <Row>
                            <Col md={12} sm={12} xs={12} lg={12}>
                                <Products
                                    setCurrentPageAndLoad={setCurrentPageAndLoad}
                                    rootPackageGroups={rootPackageGroups}
                                    setShowLoading={setLoadProductsShow}
                                    appExternal={appExternal}
                                    products={products}
                                    filterActive={filterActive}
                                    setFilterClients={setFilterClients}
                                    setFilterBrands={setFilterBrands}
                                    setFilterEditions={setFilterEditions}

                                    setProducts={setProducts}
                                    filteredProductsState={filteredProductsState}
                                    setFilteredProducts={setFilteredProducts}
                                    loadProducts={loadProducts}
                                    loadinProductsComplete={loadinProductsComplete}
                                    setFilterActive={setFilterActive}
                                    selectingMultiple={selectingMultiple}
                                    filterBrands={filterBrands}
                                    filterClients={filterClients}
                                    filterEditions={filterEditions}
                                    selectingFunction={selectingFunction}
                                    totalPages={totalPages}
                                    currentPage={currentPage}

                                    openDuplicateDialogAndSetIDZ={openDuplicateDialogAndSetIDZ}
                                    openDeleteDialogAndSetIDZ={openDeleteDialogAndSetIDZ}
                                    openMoveDialogAndSetIDZ={openMoveDialogAndSetIDZ}
                                    openRenameDialogAndSetIDZ={openRenameDialogAndSetIDZ}
                                    toggleReload={toggleReload}
                                    openDeleteDialogAndSetIDZMultiple={openDeleteDialogAndSetIDZMultiple}
                                    changeLoadingShowTop={changeLoadingShowTop}

                                    closeDeleteShow={closeDeleteShow}
                                    trigger={trigger}
                                    deleteProductCheck={deleteProductCheck}
                                    productDeleteUUID={productDeleteUUID}
                                    changeProductDeleteUUID={changeProductDeleteUUID}
                                />
                            </Col>
                        </Row>

                        {downgradeProducts > 0 ?

                            <Row>
                                <h2 id="headlineUnlock" style={{    textAlign: "center",color:"#666", marginTop: "40px",fontWeight:"200 !important" }}><Link href={"/subscription"}>You have {downgradeProducts} more products,<br/> please upgrade subscription here to access</Link> </h2>
                            </Row>

                        : null}




                    </Container>
                </section>

                <DialogAlert
                    toggleAlert={(toggleAlert: () => void) => {
                        handleTriggerWarning = toggleAlert;
                    }}
                    alertType={warningType}
                    message={warningMessage}
                />

                <DialogAcceptTeamInvite
                    toggleInvite={(toggleInvite: () => void) => {
                        handleClickInvite = toggleInvite;
                    }}
                    inviteTeamUUID={teamInvitationUUID}
                />

                <ReactTooltip />

                <LoaderProducts
                    className={`dashboard-products-loader-1-dashboard ${loadTransitionProductsShow ? 'showTransition' : 'hideTransition'} ${!loadProductsShow ? 'hiddeComplete' : ''} `}>
                    <img src={'/logoAnimate.svg'} />
                </LoaderProducts>
            </StyledDashboard>
        </>
    );
}
